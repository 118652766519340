export const categoryList = [
"Arrest Detention & Abduction",
"Bombing Explosion & Gunfire",
"Damage",
"Death",
"Displacement",
"Fire",
"Locations & Movements",
"Looting",
"Mass graves & Burial sites",
"Occupation",
"Physical Harm & Injury",
"Recruitment",
"Restrictions of movement",
"SGBV",
"Other"];


export const Actor = [
"Civilians",
// "Communal Militia",
// "Ethnic Militia",
"Foreign forces",
"Military Forces of Sudan",
"Non-state armed actors",
"Police Forces of Sudan",
"Protesters",
"RSF",
"SAF",
"Other",
"Unknown"];


export const Source = [
"CIR Verified",
"Partner Data",
"Satellite",
"ACLED"];